import Vue from 'vue'
import Vuex from 'vuex'
import {getNickName, getToken, setUserToken, clearUserToken, getRole, getUsername, setRole} from "@/plugins/base_components/cookie";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        username: getUsername(),
        nickname: getNickName(),
        token: getToken(),
        role: getRole(),
    },
    getters: {},
    mutations: {
        login: function (state, {username, nickname, role, token, last_datetime, last_ip, last_region}) {
            state.username = username
            state.nickname = nickname
            state.role = role
            state.token = token
            state.last_datetime = last_datetime
            state.last_ip = last_ip
            state.last_region = last_region
            setUserToken(username, nickname, role, token, last_datetime, last_ip, last_region)
        },
        logout: function (state) {
            state.nickname = ''
            state.token = ''
            clearUserToken()
        },
        setRole: function (state, role) {
            state.role = role
            setRole(role)
        }
    },
    actions: {},
    modules: {}
})
