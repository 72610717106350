<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>
<script>
    export default {
        name: 'app',
        data(){
            return {
            }
        },
        methods: {
        },
    }
</script>
<style>
    body {
        margin: 0;
        font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
    }
    .router-link-active{
        color: #00ace9;
    }
    .el-header, .el-footer {
        background-color: #B3C0D1;
        color: #333;
        text-align: center;
        line-height: 60px;
    }

    .el-aside {
        background-color: #D3DCE6;
        color: #333;
        text-align: center;
        line-height: 50px;
    }

    .el-main {
        /*background-color: #E9EEF3;*/
        color: #333333;
        /*text-align: center;*/
        /*line-height: 160px;*/
        width: 990px;
        padding: 0;
    }

    body > .el-container {
        margin-bottom: 40px;
    }

    .el-container:nth-child(5) .el-aside,
    .el-container:nth-child(6) .el-aside {
        line-height: 260px;
    }

    .el-container:nth-child(7) .el-aside {
        line-height: 320px;
    }
    a{
        text-decoration: none;
        color: #707070
    }
    .clearFix:after{
        content: '';
        display: block;
        clear: both;
    }
</style>
