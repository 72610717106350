import {Message} from "element-ui";

export class ModelJsSet {
    constructor(multiFunc, refreshDataCb = 'default', getDataParams = {}) {
        this.data = null
        this.page = 1
        this.size = 8
        this.total = null
        this.formVisible = false
        this.multiFunc = multiFunc
        this.getDataParams = getDataParams
        this.refreshDataCb = refreshDataCb? (refreshDataCb === 'default'?() => this.getData(this.page):refreshDataCb) : () => {}
    }


    getData = (
        $pageEvent,
        params = this.getDataParams,
        callBack = null,
        id = ''
    ) => {
        if ($pageEvent) {
            this.page = $pageEvent
        }else{
            this.page = 1
        }
        let paramsData = $pageEvent ? {page: this.page, ...params} : params
        this.multiFunc.get(paramsData, id).then(res => {
            if (res.records) {
                this.data = res.records
                this.size = res.size
                this.total = res.total
            } else {
                this.data = res
            }
            if (callBack) callBack(res)
        })
    }

    submitData = (
        formData,
        formComp,
        isCreate = true,
        contentType = 'application/json',
        callBack = null,
        successTip=true
    ) => {
        let mainFunction = () => {
            if (isCreate) {
                this.multiFunc.post(formData, contentType, undefined, successTip).then(res => {
                    this.formVisible = false
                    this.refreshDataCb()
                    callBack && callBack(res)
                })
            } else {
                this.multiFunc.patch(formData.id, formData, contentType).then(res => {
                    this.formVisible = false
                    this.refreshDataCb()
                    callBack && callBack(res)
                })
            }
        }
        if (formComp) {
            formComp.validate(valid => {
                valid && mainFunction()
            })
        }else{
            mainFunction()
        }

    }

    deleteData = (
        id,
        callBack = null,
        params = {},
        successTip=true,
    ) => {
        this.multiFunc.delete_(id, params, successTip).then(res => {
            this.refreshDataCb()
            if (callBack) callBack(res)
        })
    }
}