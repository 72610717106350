import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins'

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

// input获取焦点自定义命令，也可以通过this.$nextTick(() => {this.$refs.editForm[0].focus()})拿到对象后获取焦点
Vue.directive('focus', {
    inserted: el => {
        el.querySelector('input').focus()
    }
})
Vue.prototype.isLoginBalanceEnough = function (proxyBalance) {
    if (proxyBalance < 0.1) {
        this.$message.error('请补充代理余额')
        return false
    }
    return true
}
Vue.prototype.isChargeBalanceEnough = function (proxyBalance, captchaBalance, cardCount) {
    if (proxyBalance < 0.1 || captchaBalance < cardCount * 0.03 + 0.1) {
        this.$message.error('验证码或代理余额已不足')
        return false
    }
    return true
}
