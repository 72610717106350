import Vue from 'vue'
import VueRouter from 'vue-router'
import {getToken} from "@/plugins/base_components/cookie";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'layout',
        component: () => import('@/views/LayoutView'),
        children: [
            {
                path: '/',
                redirect: 'backstage'
            },
            {
                path: 'index',
                name: 'Index',
                component: () => import('@/views/IndexView'),
            },
            {
                path: '/personal-center',
                name: 'PersonalCenter',
                component: () => import('../views/PersonalCenterView')
            },
            {
                path: 'backstage',
                name: 'BackStage',
                component: () => import('@/views/BackStageView'),
                children: [
                    {
                        path: '/',
                        redirect: 'card-charge'
                    },
                    {
                        path: 'card-charge',
                        name: 'CardCharge',
                        component: () => import('@/views/JDECardChargeView'),
                        meta: {
                            title: '充值账户管理'
                        },
                    },
                    {
                        path: 'card-charge-detail',
                        name: 'CardChargeDetail',
                        component: () => import('@/views/JDECardChargeDetailView'),
                        meta: {
                            title: '充值明细',
                            notKeepAlive: true,
                        },
                    },
                    {
                        path: 'card-repository',
                        name: 'CardRepository',
                        component: () => import('@/views/CardRepositoryView'),
                        meta: {
                            title: '礼品卡卡库'
                        },
                    },
                    {
                        path: 'charge-report',
                        name: 'ChargeReport',
                        component: () => import('@/views/ChargeReportView'),
                        meta: {
                            title: '充值报表'
                        },
                    },
                ]
            },
        ]
    },
    {
        path: '/test',
        name: 'Test',
        component: () => import('@/views/TestView'),
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/RegisterView')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/LoginView')
    },
    {
        path: '/*',
        name: 'NotFound',
        component: () => import('../views/NotFound')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    // 将标题修改为路由设置的meta.title
    let defaultTitle = 'JD礼品卡充值系统'
    if (to.meta.title) {
        document.title = to.meta.title + '-' + defaultTitle
    }else{
        document.title = defaultTitle
    }

    let token = getToken()
    // 访问特定页面，直接通过
    if (to.name === 'index' || to.name === 'Login' || to.name === 'Register' || to.name === 'Test'){
        next()
        return
    }

    // 如果已登录，则通过
    if (token){
        next()
        return
    }
    // 未登录，跳转登录页面
    next({name: 'Login'})
})

export default router
