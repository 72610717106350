import Vue from "vue"
import VueCookie from 'vue-cookie'

Vue.use(VueCookie)

export const getToken = () => {
    return Vue.cookie.get('token')
}

export const getRole = () => {
    return Vue.cookie.get('role')
}

export const getNickName = () => {
    return Vue.cookie.get('nickname')
}
export const getUsername = () => {
    return Vue.cookie.get('username')
}
export const getLastDatetime = () => {
    return Vue.cookie.get('last_datetime')
}
export const getLastIP = () => {
    return Vue.cookie.get('last_ip')
}
export const getLastRegion = () => {
    return Vue.cookie.get('last_region')
}

export const setUserToken = (username, nickname, role, token, last_datetime, last_ip, last_region) => {
    Vue.cookie.set('username', username, {expires: '1D'})
    Vue.cookie.set('nickname', nickname, {expires: '1D'})
    Vue.cookie.set('role', role, {expires: '1D'})
    Vue.cookie.set('token', token, {expires: '1D'})
    Vue.cookie.set('last_datetime', last_datetime, {expires: '1D'})
    Vue.cookie.set('last_ip', last_ip, {expires: '1D'})
    Vue.cookie.set('last_region', last_region, {expires: '1D'})
}
export const clearUserToken = () => {
    Vue.cookie.delete('username')
    Vue.cookie.delete('nickname')
    Vue.cookie.delete('role')
    Vue.cookie.delete('token')
    Vue.cookie.delete('last_datetime')
    Vue.cookie.delete('last_ip')
    Vue.cookie.delete('last_region')
}
export const setRole = (role) => {
    Vue.cookie.set('role', role, {expires: '1D'})
}