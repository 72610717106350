export const validateCorrectAnswer = condition => {
    return (rule, value, cb) => {
        if (condition) {
            cb(new Error('多选题至少两个选项'))
        } else {
            cb()
        }
    }
}

export const validateEnvironment = (rule, value, cb) => {
    if (!/^[0-9.,;]+$/.test(value)) {
        cb(new Error('含非法字符'))
        return
    }
    for (let ip of value.split(';')) {
        let numList = ip.split('.')
        if (numList.length !== 4) {
            cb(new Error('长度有误'))
            return
        }
        for (let num of numList) {
            if (!/^\d+$/.test(num) || parseInt(num) < 0 || parseInt(num) > 255) {
                cb(new Error('值域有误'))
                return
            }
        }
    }
}