let startTimeKey = 'startTime'  // `startTime_${randomChar}`
let restSecondsKey = 'restSeconds'  // `restSeconds_${randomChar}`

export function getStandardDatetime(){
    let now = new Date()
    let year = now.getFullYear()
    let month = String(now.getMonth() + 1).padStart(2, '0') // 月份从0开始，需要加1，并且保证两位数
    let day = String(now.getDate()).padStart(2, '0')
    let hours = String(now.getHours()).padStart(2, '0')
    let minutes = String(now.getMinutes()).padStart(2, '0')
    let seconds = String(now.getSeconds()).padStart(2, '0')

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`

}
export function storedTime(restSeconds){
    let currentTime = new Date().getTime()

    localStorage.setItem(startTimeKey, currentTime.toString())
    localStorage.setItem(restSeconds, restSeconds)
}

export function recoverTime(){
    let startTime = parseInt(localStorage.getItem(startTimeKey))
    let resetSeconds = parseInt(localStorage.getItem(restSecondsKey))
    if (resetSeconds){
        let currentTime = new Date().getTime()
        let elapsedTime = currentTime - startTime
        localStorage.removeItem(startTimeKey)
        localStorage.removeItem(restSecondsKey)
        return resetSeconds - elapsedTime
    }
    return 0
}