import Vue from "vue";
import "@/plugins/base_components"
import "@/plugins/custom_components"
import * as utils from "@/plugins/utils"

// 全局变量/函数
Vue.prototype.$var = 123
Vue.prototype.$globalUtils = utils
Vue.prototype.$isDevEnv = process.env.NODE_ENV === 'development'

// 全局组件
// Vue.component('my-pagination', MyPagination)


// 自定义vnode，input获取焦点
Vue.directive('focus', {
    inserted: el => {
        el.querySelector('input').focus()
    }
})