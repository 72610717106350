import Vue from 'vue'
import axios from 'axios'
import VueAxios from "vue-axios";
import {getToken} from "@/plugins/base_components/cookie";
import router from '../../router'
import store from '../../store'
import {Message} from 'element-ui'

Vue.use(VueAxios, axios)

// 只有页面第一次加载时执行
axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? `http://139.196.10.120:8495/api/` : `${window.location.origin}/api/`
// axios.defaults.headers.common['Authorization'] = getToken()
// axios.defaults.headers.post['Content-Type'] = 'application/json'
// axios.defaults.headers.put['Content-Type'] = 'application/json'
// 超时时间
axios.defaults.timeout = 30000;

// 请求拦截器，axios每次请求均执行一次
axios.interceptors.request.use(function (config) {
    const token = getToken()
    if (token) {
        config.headers.common['Authorization'] = token
    }
    return config
})

// 响应拦截器
axios.interceptors.response.use(function (response) {
    // API请求执行成功，相应状态码200，自动执行
    if (response.data.code === '2000') {
        Message.error('认证过期，请重新登录...')
        // store.commit('logout')
        // router.replace({name: 'Login'})
        return Promise.reject('认证过期')
    }
    return response
}, function (error) {
    // API请求执行失败，响应状态码400/500，自动执行
    if (error.response.status === 401) {
        // 清空store数据和cookie
        store.commit('logout')
        // replace不让他可以返回
        router.replace({name: 'Login'})
        return Promise.reject(error)
    }
    return Promise.reject(error)
})