
export const setContentToClipBoard = test => {
    const textareaEle = document.createElement('textarea')
    textareaEle.value = test
    document.body.appendChild(textareaEle)
    textareaEle.select()
    document.execCommand('copy')
    document.body.removeChild(textareaEle)
}


export const debounceGetData = (instance, callBack, debounceTime = 300) => {
    if (instance.debounceGetDataTimer) {   // 名字长避免污染实例变量
        clearTimeout(instance.debounceGetDataTimer)
    }
    instance.debounceGetDataTimer = setTimeout(() => {
        callBack()
    }, debounceTime)
}



